import $ from 'jquery'


const initBrightcovePlayer = () => {
  const $videos = $('.video-js')
  let pageType = 'standard'

  // on the feature article page setup the video scroll event
  if ($('.feature_section').length > 0 && $videos.length > 0 && window.rnn.site.isDesktop === true) {
    pageType = 'feature'
    $(window).scroll(_.debounce(() => featureSectionBrightcove(), 500))
  }

  $videos.each(() => {
    const $video = $(this)
    const player = videojs($video.attr('id'))
    const adServerURL = generateAdURL()

    if (adServerURL) {
      player.ima3.settings.serverUrl = adServerURL
    }

    // autoplay on desktop and mobile video detail pages.
    if ($('#video-detail').length > 0 || $('.videoDetail').length > 0) {
      player.autoplay(true)
    }

    player.on('firstplay', () => brightcoveFirstPlay(player, $video))
    player.on('ended', () => brightcoveComplete(player, $video))

    if (pageType === 'feature') {
      // adding a custom var to the player object to keep track of the scroll pause state
      player.scrollPaused = true

      if ($video.closest('.mainVideo').length > 0) {
        player.autoplay(true)
      }
    }
  })
}

const generateAdURL = () => {
  const stagingString = 'staging.apnarm'
  const cleanTags = window.rnn.modules.dfp_advertising.cleanAdString(pageData.tags)
  let adServerURL = ''
  let encodedString = ''
  let site = pageData.site
  let urlString = pageData.url.trim()

  if (window.location.href.indexOf(stagingString) > -1) {
    urlString = stagingString + ' ' + urlString
  }

  if (window.rnn.site.isMobile === true) {
    site = 'm.' + site
  }

  encodedString = encodeURIComponent('tag=' + cleanTags +
    '&source=' + pageData.source +
    '&region=' + pageData.region +
    '&category=' + pageData.sector +
    '&URL=' + urlString)

  adServerURL = 'https://pubads.g.doubleclick.net/gampad/ads' +
    '?sz=661x372&iu=1054219/' + site + '.videos&ciu_szs' +
    '&impl=s&gdfp_req=1&env=vp&output=xml_vast2' +
    '&unviewed_position_start=1' +
    '&url=' + encodeURIComponent('[referrer_url]') +
    '&correlator=' + encodeURIComponent('[timestamp]') +
    '&cust_params=' + encodedString

  return adServerURL
}

const brightcoveFirstPlay = (player, $video) => {
  const $videoWrapper = $video.closest('.video')
  const $carousel = $videoWrapper.find('.video-carousel')

  if ($carousel.length > 0) {
    $carousel.slideDown('slow')

    if (window.rnn.site.isMobile === true) {
      // on resize check if the carousels should have controls
      $(window).resize(_.debounce(() => {
        $('.video-carousel').each(() => window.rnn.modules.carousels.checkWidth($(this), $(this).find('.item')))
      }, 500))
    }
  }

  const $title = $videoWrapper.find('.videoTitle')
  if ($title.length > 0) {
    $title.html(player.mediainfo.name).show()
  }
}

const brightcoveComplete = (player, $video) => {
  // check if we have a more videos carousel
  const $moreVideos = $video.closest('.video').find('.video-carousel')

  if ($moreVideos.length > 0) {
    const $moreItems = $moreVideos.find('.item-video')

    if ($moreItems.length > 0) {
      const $selected = $moreItems.first()
      if ($selected.length > 0) {
        $moreVideos.find('.next').click()
        const videoId = $selected.data('videoid')
        if (videoId) {
          player.catalog.getVideo(videoId, (error, video) => {
            player.catalog.load(video)
            player.play()
          })
        }
      }
    }
  }
}

const featureSectionBrightcove = () => {
  $('.video-js').each(() => {
    const $video = $(this)
    const player = videojs($video.attr('id'))

    if ($video.isOnScreen()) {
      if (player.paused() && player.scrollPaused === true) {
        player.play()
        player.scrollPaused = false
      }
    } else {
      if (!player.paused()) {
        player.pause()
        player.scrollPaused = true
      }
    }
  })
}

const initYoutube = () => {
  if ($('.youtubeVideo').length > 0) {
    addYoutubeAPI()
  }
}

// This is what the iframe api calls after its loaded
window.onYouTubeIframeAPIReady = () => {
  $('.youtubeVideo').each(function () {
    buildYoutubePlayer($(this), $(this).data('videoid'), $(this).data('autoplay'), $(this).data('endCallback'))
  })
}

const addYoutubeAPI = () => {
  const scriptUrl = 'https://www.youtube.com/iframe_api'

  if ($('head script[src="' + scriptUrl + '"]').length > 0) {
    return
  } else {
    const tag = document.createElement('script')
    const firstScriptTag = document.getElementsByTagName('script')[0]

    tag.src = scriptUrl
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
  }
}

// if we dont have a youtube id but we have a youtube url this will sort you out
const buildURLYoutubePlayer = ($space, url, autoplay, endCallback) => {
  const videoId = youtubeId(url)
  buildYoutubePlayer($space, videoId, autoplay, endCallback)
}

const buildYoutubePlayer = ($space, videoId, autoplay, endCallback) => {
  // if its a dynamic video and theres no player div
  if (!$space.hasClass('youtubeVideo')) {
    $space.append('<div class="youtube_video_wrapper"><div class="youtubeVideo"></div></div>')
    // assign space to the new html you've made
    $space = $space.find('.youtubeVideo')
    $space.data({
      videoid: videoId,
      autoplay: autoplay,
      endCallback: endCallback
    })
  }

  if (typeof YT === 'undefined') {
    addYoutubeAPI()
  } else {
    if (videoId) {
      $space.append('<div class="youtubePlayer"><div id="youtube' +
        videoId + '"></div></div>')

      new YT.Player(`youtube${videoId}`, {
        height: '390',
        width: '640',
        videoId: videoId,
        playerVars: { 'autoplay': autoplay === true ? 1 : 0 },
        events: {
          'onReady': function (event) {
            if (autoplay === 1) {
              event.target.playVideo()
            }
          },
          'onStateChange': function (event) {
            if (event.data === YT.PlayerState.ENDED) {
              if (_.isFunction(endCallback)) {
                endCallback()
              }
            }
          }
        }
      })
    }
  }
}

// this will return the youtube id from any youtube url
const youtubeId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/,
    match = url.match(regExp)
  if (match && match[2].length === 11) {
    return match[2]
  } else {
    return url // I sure hope this is right!
  }
}

const initVideos = () => {
  initYoutube()
  initBrightcovePlayer()
}

export default {
  init: initVideos,
  initBrightcovePlayer: initBrightcovePlayer,
  initYoutube: initYoutube,
  buildURLYoutubePlayer: buildURLYoutubePlayer,
  buildYoutubePlayer: buildYoutubePlayer
}

