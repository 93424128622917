// Ported RNN modules
// @TODO: Refactor the modules listed to remove jquery and simplify logic
//
// @TODO: This module serves primarily as a midpoint for porting old functionality
// we rely on from the legacy modules to the new code JS codebase. Once the work
// around this settles down it's primary purpose then shifts to being a core module
// used between BSS/MYT and providing public functions to reduce template js logic.
//
// @NOTE: Maybe during that refactor we should rename this module to finda to signal
// the api changeover and the new (To be fully worked out) pattern for calling into
// webpack contained javascript
import B64 from './rnn/B64'
import User from './rnn/User'
import Videos from './rnn/Videos'
import AdminUI from './rnn/AdminUI'
import Omniture from './rnn/Omniture'
import Galleries from './rnn/Galleries'
import NCAOmniture from './rnn/NCAOmniture'
import Maps from './rnn/Maps' // @TODO Refactor
import ItemCarousel from './rnn/ItemCarousel' // @TODO Refactor
import Facebook from './rnn/Facebook' // @TODO Refactor
import LocationSelector from './rnn/LocationSelector'

// Sentry SDK
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

// Ported Styles
import '../styles/rnn.scss'

// global library exports to the window for development assistance
import u from 'umbrellajs'

// RNN init() - The main entry point for rnn modules ported over from
// the legacy JS codebase. This module will typically be imported and loaded
// onto `window.rnn`.
//
// @TODO Parse/validate siteconfig, write it in TS for typings
// and handle siteconfig as a seperate module that can be imported
export function init(siteConfig) {

  // Update the global site object
  window.rnn.site = siteConfig

  // Initialise Sentry
  if (siteConfig.sentry) {
    Sentry.init({
      dsn: siteConfig.sentry.dsn,
      environment: siteConfig.sentry.environment,
      release: siteConfig.sentry.release,
      tags: { site: siteConfig.sentry.site },
      sampleRate: 0.5,
      tracesSampleRate: siteConfig.sentry.traceRate,
      allowUrls: siteConfig.sentry.whitelistUrls,
      denyUrls: siteConfig.sentry.ignoreUrls,
      ignoreErrors: siteConfig.sentry.ignoreErrors,
      integrations: [
        new CaptureConsoleIntegration({
          levels: ['error', 'warn', 'assert']
        }),
      ],
      // debug: true,
    })
  } else { console.debug('No sentry config in siteConfig, not loading sentry') }

  // Initialise the "Core" Modules which provide exported objects at
  // `rnn.modules` to support external function calls
  User.init(siteConfig)

  if (typeof window.NCA_OMNITURE_ENABLED === 'boolean' && window.NCA_OMNITURE_ENABLED === true) {
    NCAOmniture.init()
  } else {
    Omniture.init()
  }

  Facebook.init(siteConfig)
  AdminUI.init(siteConfig)
  Galleries.init()
  Videos.init()

  // @NOTE: We generally don't want to export modules as communication over the
  // `window` object as it is error prone at best. For modules that don't require JS
  // calls from inline template, just use ES6 import/export
  LocationSelector()
  ItemCarousel()
  B64()
  Maps(siteConfig)
  // Finially bind libraries to the window to not encourage any blind usage
  window.u = window.u || u
}

export default {
  init,
  site: {},
  modules: {
    user: User,
    omniture: Omniture,
    facebook: Facebook,
    adminUI: AdminUI,
    galleries: Galleries,
  },
}
